import { Booking, Demographics, MemberProfile } from "@/types";

export const sortAlphabetically = (a: string, b: string): number => {
  if (typeof a !== "string" || typeof b !== "string") {
    a = a.toString();
    b = b.toString();
  }
  const aName = a.toLowerCase();
  const bName = b.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const stringValueExists = (value: unknown): value is string =>
  typeof value === "string" && value.trim().length > 0;

export const isNumber = (value: unknown): value is number => typeof value === "number";

export const getIdentifierString = ({
  eventId,
  start,
  slotId,
  ticketId,
  ticketSeries,
  memberId,
  funderMemberId,
}: {
  eventId: string;
  start: Date;
  slotId?: string | undefined;
  ticketId?: string | undefined;
  ticketSeries?: number | undefined;
  memberId: string;
  funderMemberId?: string | undefined;
}): string => {
  const idParts = [eventId, start.toISOString(), memberId];
  
  if (stringValueExists(funderMemberId)) {
    idParts.push(funderMemberId);
  }

  if (stringValueExists(slotId)) {
    idParts.push(slotId);
  }

  if (stringValueExists(ticketId)) {
    idParts.push(ticketId);
  }

  if (typeof ticketSeries === 'number') {
    idParts.push(ticketSeries.toString());
  }

  return idParts.join("-");
};

export const getBookingIdentifier = (booking: Booking) => {
  if (booking.unnamed) {
    return getIdentifierString({
      ...booking,
      // As we add a random id for unnamed bookings as member ids, just use unnamed so we can actually check the bookings are unique
      memberId: "unnamed", 
    });
  } else {
    return getIdentifierString(booking);
  }
};

export const isNonEmptyArray = (value: unknown): boolean =>
  Array.isArray(value) && value.length > 0;

export const isEmptyArray = (value: unknown): boolean =>
  Array.isArray(value) && value.length === 0;

export const getSelectedKeys = (filterObject: Record<string, boolean>) =>
  Object.keys(filterObject).filter((key) => filterObject[key]);

export const createSetFromValue = (
  value: unknown | string | string[]
): Set<string> => {
  let selectedSet = new Set<string>();

  if (isNonEmptyArray(value)) {
    selectedSet = new Set<string>(value as string[]);
  } else if (stringValueExists(value)) {
    selectedSet.add(value);
  }

  return selectedSet;
};

export const getStringAsArray = (value: string | string[]): string[] => {
  if (stringValueExists(value)) {
    return [value];
  } else if (isNonEmptyArray(value)) {
    return value;
  }
  return value;
};

// Do i really want to write this? Absolutely not but i've been failed by every
// other attempt and this is quicker.
export const toQuery = (params: Record<string, string | boolean>): string => {
  const queryEntries: Array<string> = [];
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      queryEntries.push(...value.map((val) => `${key}=${val}`));
    } else {
      queryEntries.push(`${key}=${value}`);
    }
  });

  return queryEntries.join("&");
};

const keepExistingValues = <T extends object>(obj: T): T =>
  Object.keys(obj).reduce<T>((lookup, b) => {
    if (obj[b] !== undefined && obj[b] !== null) {
      lookup[b] = obj[b];
    }
    return lookup;
  }, {} as T);

export const cleanMemberProfileFields = (member: MemberProfile): MemberProfile => {
  const cleanProfile = keepExistingValues<MemberProfile>(member);

  if (member.DEMOGRAPHICS) {
    cleanProfile.DEMOGRAPHICS = keepExistingValues<Demographics>(member.DEMOGRAPHICS);
  }

  return cleanProfile;
};
